import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { getCourse, getCourseFree } from '../../actions/course';
import LinkItem from './LinkItem';
import { withRouter, Redirect } from 'react-router-dom';
import './courseLinks.css';
import SideNav from './../layout/SideNav';
import DisplayShowcase from './DisplayShowcase';
import { addPage } from './../courses/redirectHelper';
import {loadUser } from './../../actions/auth'

const CourseLinks = ({
  auth,
  getCourse,
  getCourseFree,
  course: { course, loading, error },
  match,
  history,
  loadUser,
}) => {
  const [courseDetails, setCourseDetaile] = useState({});
  const [load, setLoad] = useState(false);
  useEffect(() => {
    init();
  }, [match.params.id, auth.isAuthenticated]);

  useEffect(() => {
    checkToken();
  }, [match.params.id,match.params.link, auth.isAuthenticated]);


  const checkToken = async() => {
    await loadUser()
  }

  const init = async () => {
    setLoad(true);
    let d;

    if (auth.isAuthenticated === true) {
      d = await getCourse(match.params.id);
    }
    if (auth.isAuthenticated === false) {
      d = await getCourseFree(match.params.id);
    }

    setLoad(false);
    setCourseDetaile(d);
  };

  const showloading = () => {
    if (load) {
      return <div className="iframe-loading">Loading&#8230;</div>;
    }
  };

  const displayItem = (courseDetails) => {
    let linkIndex = courseDetails.links.length-1;

    if (courseDetails.firstLink){
      for (let i=0;i<courseDetails.links.length; i++){
        if (courseDetails.firstLink == courseDetails.links[i]._id){
          linkIndex = i;
          return <LinkItem linkObject={courseDetails.links[linkIndex]} />
        }
      }
    }
    return <LinkItem linkObject={courseDetails.links[linkIndex]} />
                      
  }

  return !auth.loading ? (
    // !auth.isAuthenticated ? (
    //   <div>
    //     {addPage('/courses/' + match.params.id)}
    //     <Redirect to="/login" />
    //   </div>
    // ) :
    loading ? (
      <div className="iframe-loading">Loading&#8230;</div>
    ) : (
      <Fragment>
        {showloading()}
        
        {match.params.id !== '5f5b21f02f203435d78d5502'
          ? error
            ? history.push(`/syllabus/${match.params.id}`)
            : null
          : null}

        {courseDetails &&
        courseDetails.links &&
        courseDetails.links.length === 1 &&
        courseDetails.links[0].type === 'showcase' ? (
          <DisplayShowcase linkObject={course.links[0]} />
        ) : (
          <Fragment>
            {courseDetails && courseDetails.links ? (
              <Fragment>
                <SideNav
                  name={courseDetails.nameOfCourse}
                  links={courseDetails.links}
                  courseId={courseDetails._id}
                />
                <div id="main">
                  {courseDetails && courseDetails.links.length > 0 ? (
                    match.params.link ? (
                      <LinkItem
                        linkObject={courseDetails.links.find(
                          (l) => l._id === match.params.link
                        )}
                      />
                    ) : (
                      <Fragment>
                        {
                          displayItem(courseDetails)
                        }
                      </Fragment>
                    )
                  ) : (
                    <p>אין קורסים</p>
                  )}
                </div>
              </Fragment>
            ) : null}
          </Fragment>
        )}
      </Fragment>
    )
  ) : null;
};

CourseLinks.propTypes = {
  getCourse: PropTypes.func.isRequired,
  getCourseFree: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  course: state.course,
  auth: state.auth,
});

export default withRouter(
  connect(mapStateToProps, { getCourse, getCourseFree, loadUser })(CourseLinks)
);
