import React, { useState, useEffect } from 'react';
import { getAllUsers } from './../../actions/user';

const AllUsers = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);

  const init = () => {
    getAllUsers().then(data => {
      if (data.error) {
        setError(data.error);
      } else {
        setData(data);
      }
    });
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div>
      {data.map((user, i) => (
        <tr key={i} style={{ padding: '10px' }}>
          <td style={{ padding: '10px' }}>{i}</td>
          <td style={{ padding: '10px' }}>{user._id}</td>
          <td style={{ padding: '10px' }}>{user.name}</td>
          <td style={{ padding: '10px' }}>{user.date}</td>
          <td style={{ padding: '10px' }}>{user.email}</td>
          <td style={{ padding: '10px' }}>{user.phone}</td>
        </tr>
      ))}
    </div>
  );
};

export default AllUsers;
