import axios from 'axios';
import { setAlert } from './alert';

//get the showcase from the same school
export const getAllUsers = async () => {
  const res = await axios.get('/api/users/all');
  let { message, data, status } = res.data;
  if (status === 200) return data;
  else return setAlert(message, 'danger');
};
