import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setAlert } from './../../actions/alert';
import PropTypes from 'prop-types';
import { updateCourse } from '../../actions/course';
import axios from 'axios';
import { decrypt } from '../../utils/decrypt';
import {updateLink, deleteLink} from '../../actions/course'

const UpdateCourse = () => {
    const [courses,setCourses] = useState([])
    const [selectedCourse,setSelectedCourse]=useState({})
    const [tempLinks, setTempLinks] = useState([]);

    useEffect(()=> {
        getCourses()
    },[])

  const getCourses = async () => {
    const res = await axios.get('/api/courses');
    let { data } = res.data;
    console.log('data: ',data)
    setCourses([...data])
  }

  const handleSelectionChange = (event) => {
    const selectedCourseId = event.target.value;
    console.log('selectedCourseId: ',selectedCourseId)
    const course = courses.find(c => c._id === selectedCourseId);
    console.log('selected course: ',course)
    course.links = course.links.map(link => {return{...link, link: decrypt(link.link)}})
    setSelectedCourse(course);
    setTempLinks(course.links || [])
  }

  const handleInputChange = (id, field, value) => {
    const updatedTempLinks = tempLinks.map(link =>
      link._id === id ? { ...link, [field]: value } : link
    );
    setTempLinks(updatedTempLinks);
  };

  function containsPreview(link) {
    return link.includes("preview");
  }

  const handleUpdateClick = async (id) => {
    console.log('id: ',id)
    const updatedLink = tempLinks.find(link => link._id === id);
    console.log('updatedLink ',updatedLink)
    let { type, link, name, _id, indexLink, subject} = updatedLink
    if (type === 'drive' && !containsPreview(link)) {
        console.log('link 1 ', link);
        console.log('link 2 ', link.substring(0, link.length - 16));
        console.log('link 2 ', link.substring(0, link.length - 16) + 'preview');
        link = link.substring(0, link.length - 16) + 'preview';
      } else if (type === 'showcase' && !link.includes('https://vimeo.com/showcase')) {
        console.log('link 1 ', link);
        let place = link.indexOf('showcases');
        console.log('place: ', place);
        console.log('link 2 ', link.substring(place + 9, link.length));
  
        link =
          'https://vimeo.com/showcase' + link.substring(place + 9, link.length);
      }
      console.log('type:', type, ' Link:', link);
      updateLink({ courseId:selectedCourse._id, type, link, name, linkId: _id, subject, indexLink});
  };

  const handleDeleteClick = async (id) => {
    console.log('id: ',id)
    const link = selectedCourse.links.find(link => link._id === id);
    console.log('deletedLink ',link)
    console.log('from course: ',selectedCourse._id)
    deleteLink({ courseId:selectedCourse._id, linkId: link._id});
    // Remove the link from tempLinks
    const updatedLinks = tempLinks.filter(link => link._id !== id);
    setSelectedCourse({
        ...selectedCourse,
        links: updatedLinks
      });
	setTempLinks(updatedLinks);
  };

  return (
    <Fragment>
      <div className="sign_up-content">
        <select onChange={handleSelectionChange} defaultValue="">
            <option value="" disabled>Select a course</option>
            {courses.map(course => (
            <option key={course._id} value={course._id}>
                {course.nameOfCourse}
            </option>
            ))}
        </select>
        </div>
        <div>
      {selectedCourse.links && selectedCourse.links.map(link => (
        <div className="row-1" key={link._id} style={{ marginBottom:"10px", justifyContent:"center"}}>
          <div>
            <div style={{ display:"flex", justifyContent:"center", alignItems:"center"}}>
              <span style={{ marginRight:"10px"}}>Name: </span>
              <input
                type="text"
                value={tempLinks.find(tempLink => tempLink._id === link._id).name}
                onChange={(e) => handleInputChange(link._id, 'name', e.target.value)}
                style={{width:"700px", border:"1px solid black", marginBottom:"5px"}}
              />
            </div>
            <div style={{ display:"flex", justifyContent:"center", alignItems:"center"}}>
            <span style={{ marginRight:"10px"}}>URL: </span>
              <input
                type="text"
                value={tempLinks.find(tempLink => tempLink._id === link._id).link}
                onChange={(e) => handleInputChange(link._id, 'link', e.target.value)}
                style={{width:"700px", border:"1px solid black", marginBottom:"5px"}}
              />
            </div>
            <div style={{ display:"flex", justifyContent:"center", alignItems:"center"}}>
              <span style={{ marginRight:"10px"}}>Subject: </span>
              <input
                type="text"
                value={tempLinks.find(tempLink => tempLink._id === link._id).subject}
                onChange={(e) => handleInputChange(link._id, 'subject', e.target.value)}
                style={{width:"700px", border:"1px solid black", marginBottom:"5px"}}
              />
            </div>
            <div style={{ display:"flex", justifyContent:"center", alignItems:"center"}}>
              <span style={{ marginRight:"10px"}}>Link Index: </span>
              <input
                type="text"
                value={tempLinks.find(tempLink => tempLink._id === link._id).indexLink}
                onChange={(e) => handleInputChange(link._id, 'indexLink', e.target.value)}
                style={{width:"700px", border:"1px solid black", marginBottom:"5px"}}
              />
            </div>
            <div style={{ display:"flex", justifyContent:"center", alignItems:"center"}}>
            <span style={{ marginRight:"10px"}}>Type: </span>
              <select
                value={tempLinks.find(tempLink => tempLink._id === link._id).type}
                onChange={(e) => handleInputChange(link._id, 'type', e.target.value)}
              >
                
                <option value="video">video</option>
                <option value="pdf">pdf</option>
                <option value="drive">drive</option>
                <option value="showcase">showcase</option>
                <option value="folder">folder</option>
                <option value="youtube">youtube</option>
              </select>
            </div>
            
            <div>
                <div>
                <button onClick={() => handleUpdateClick(link._id)} style={{marginRight:"10px"}}>Update</button>
                <button onClick={() => handleDeleteClick(link._id)}>Delete</button>
                </div>
            
            </div>
          </div>
        </div>
      ))}
    </div>
    
    </Fragment>
  );
};

UpdateCourse.propTypes = {
  setAlert: PropTypes.func.isRequired,
  updateCourse: PropTypes.func.isRequired,
};

export default connect(null, { setAlert, updateCourse })(UpdateCourse);
