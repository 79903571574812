import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { setAlert } from './../../actions/alert';
import PropTypes from 'prop-types';
import { updateGeneral } from '../../actions/general';

const AddGeneralDef = ({ setAlert }) => {
  const [formData, setFormData] = useState({
    durationCourseDays: '',
    durationCourseMonths: '',
    durationCourseYears: ''
  });

  const {
    durationCourseDays,
    durationCourseMonths,
    durationCourseYears
  } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();

    updateGeneral({
      durationCourseDays,
      durationCourseMonths,
      durationCourseYears
    });
  };

  return (
    <Fragment>
      <div className="sign_up-content">
        <div className="sign_up-right">
          <h2> הגדרות בסיסיות</h2>
          <form className="form" onSubmit={e => onSubmit(e)}>
            <div className="row-1">
              <input
                type="text"
                placeholder="ימים"
                name="durationCourseDays"
                value={durationCourseDays}
                onChange={e => onChange(e)}
              />
            </div>

            <div className="row-1">
              <input
                type="text"
                placeholder="חודשים"
                name="durationCourseMonths"
                value={durationCourseMonths}
                onChange={e => onChange(e)}
              />
            </div>
            <div className="row-1">
              <input
                type="text"
                placeholder="שנים"
                name="durationCourseYears"
                value={durationCourseYears}
                onChange={e => onChange(e)}
              />
            </div>

            <div className="button">
              <button type="submit" className="btn-submit">
                הוסף
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

AddGeneralDef.propTypes = {
  setAlert: PropTypes.func.isRequired
};

export default AddGeneralDef;
