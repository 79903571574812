import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getShowcaseByIdB } from '../../actions/course';
import Spinner from '../layout/Spinner';
import ShowItemB from './ShowItemB';
import { withRouter, Redirect } from 'react-router-dom';
import { addPage } from './redirectHelper';

const ShowcasesB = ({
  auth,
  getShowcaseByIdB,
  showcase: { courseA, loading, error },
  match,
  history,
}) => {
  useEffect(() => {
    getShowcaseByIdB(match.params.school, match.params.idB,history);
  }, [match.params.school]);

  return loading ? (
      <div className="iframe-loading">Loading&#8230;</div>
    ) : (
      <Fragment>
       {
         !auth.isAuthenticated && addPage('/showcases/by/' + match.params.school+ '/'+ match.params.idB)
        }
        {
          !auth.isAuthenticated || error 
          ? history.push(
              `/login`
            )
          : null
        }
         { error 
          ? history.push(
              `/showsyllabus/${match.params.school}/${match.params.idB}`
            )
          : null
        } 
        

        <section className="text-banner text-banner-2">
          <h1>{courseA && courseA.nameB}</h1>
        </section>
        {courseA ? (
          <section className="solutions-menu">
            <div className="menu-content about">
              <div className="course course-2">
                {courseA.nameOfCourseC
                  ? courseA.nameOfCourseC
                      .sort((a, b) => (a.indexLink > b.indexLink ? 1 : -1))
                      .map((c) => (
                        <ShowItemB
                          key={c._id}
                          course={c}
                          school={match.params.school}
                          idB={courseA._id}
                        />
                      ))
                  : null}
              </div>
            </div>
          </section>
        ) : null}
      </Fragment>
    )
 
};

ShowcasesB.propTypes = {
  getShowcaseByIdB: PropTypes.func.isRequired,
  showcase: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  showcase: state.showcase,
  auth: state.auth,
});

export default connect(mapStateToProps, { getShowcaseByIdB })(
  withRouter(ShowcasesB)
);
