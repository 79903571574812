/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react';
import './courseLinks.css';
import config from '../../utils/configs.json';
const $ = require('jquery');
const EMBEDED_TYPE = {
  VIDEO: 'video',
  PDF: 'pdf',
  DRIVE: 'drive',
  YOUTUBE: 'youtube',
  FOLDER: 'folder',
};

let myInterval = null;
let initialInterval = null;

const ShowLinkItem = ({ linkObject }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    initialInterval = setInterval(() => {
      $(document).ready(function () {
        $('#myFrameItem').attr(
          'src',
          `${
            linkObject.type === EMBEDED_TYPE.PDF
              ? `https://docs.google.com/gview?url=${linkObject.link}&embedded=true`
              : linkObject.type === EMBEDED_TYPE.FOLDER
              ? `https://drive.google.com/embeddedfolderview?id=${linkObject.link}#grid`
              : linkObject.type === EMBEDED_TYPE.VIDEO
              ? config.vimeoURL + linkObject.link
              : linkObject.type === EMBEDED_TYPE.YOUTUBE
              ? `https://www.youtube.com/embed/videoseries?list=${linkObject.link}`:linkObject.link
          }`
        );
      });
    }, 3000);
  }, []);

  useEffect(() => {
    setLoading(true);
    $(document).ready(function () {
      $('#myFrameItem').attr(
        'src',
        `${
          linkObject.type === EMBEDED_TYPE.PDF
            ? `https://docs.google.com/gview?url=${linkObject.link}&embedded=true`
            : linkObject.type === EMBEDED_TYPE.FOLDER
            ? `https://drive.google.com/embeddedfolderview?id=${linkObject.link}#grid`  
            : linkObject.type === EMBEDED_TYPE.VIDEO
            ? config.vimeoURL + linkObject.link
            : linkObject.type === EMBEDED_TYPE.YOUTUBE
            ? `https://www.youtube.com/embed/videoseries?list=${linkObject.link}`:linkObject.link
        }`
      );
    });
    myInterval = setInterval(() => {
      $(document).ready(function () {
        $('#myFrameItem').attr(
          'src',
          `${
            linkObject.type === EMBEDED_TYPE.PDF
              ? `https://docs.google.com/gview?url=${linkObject.link}&embedded=true`
              : linkObject.type === EMBEDED_TYPE.FOLDER
              ? `https://drive.google.com/embeddedfolderview?id=${linkObject.link}#grid`  
              : linkObject.type === EMBEDED_TYPE.VIDEO
              ? config.vimeoURL + linkObject.link
              : linkObject.type === EMBEDED_TYPE.YOUTUBE
              ? `https://www.youtube.com/embed/videoseries?list=${linkObject.link}`:linkObject.link
          }`
        );
      });
    }, 3000);
  }, [linkObject.link]);

  return (
    <div className="iframe-wrapper">
      <div className="iframe-container">
        <div
          className={
            linkObject.type === 'video' ? 'display-none' : 'iframe-hider'
          }
        >
          <div className="vertically-align">M.O.K</div>
        </div>
        {loading && <div className="iframe-loading">Loading&#8230;</div>}
        <iframe
          onLoad={() => {
            setLoading(false);
            clearInterval(initialInterval);
            clearInterval(myInterval);
          }}
          height="600"
          width="1000"
          id="myFrameItem"
          allowFullScreen
          className="iframe-element"
        />
      </div>
    </div>
  );
};

export default ShowLinkItem;
