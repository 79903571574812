import axios from 'axios';
import { setAlert } from './alert';

//get the general definition
export const getSpecificDefintion = async () => {
  const res = await axios.get(`/api/general/specific`);
  let { message, data, status } = res.data;
  if (status === 200) return data;
  else return setAlert(message, 'danger');
};

//Add General Defination
export const addGeneral = async ({
  durationCourseDays,
  durationCourseMonths,
  durationCourseYears
}) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({
    durationCourseDays,
    durationCourseMonths,
    durationCourseYears
  });

  const res = await axios.post('/api/general/add', body, config);
  let { errors, data, status, message } = res.data;
  if (status === 200) {
    setAlert('added', 'success');
    return data;
  } else if (status === 400) setAlert(errors.msg, 'danger');
  else return setAlert(message);
};

//update General Defination
export const updateGeneral = async ({
  durationCourseDays,
  durationCourseMonths,
  durationCourseYears
}) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({
    durationCourseDays,
    durationCourseMonths,
    durationCourseYears
  });

  const res = await axios.put('/api/general/update', body, config);
  let { errors, data, status, message } = res.data;
  if (status === 200) {
    setAlert('added', 'success');
    return data;
  } else if (status === 400) setAlert(errors.msg, 'danger');
  else return setAlert(message);
};
