import React, { Fragment, useState } from 'react';

import { addFirstLink } from './../../actions/course';
const AddFirstLink = () => {
  const [formData, setFormData] = useState({
    courseId: '',
    firstLink: '',
  });

const [showPopup,setShowPopup] = useState(false)

  let { courseId, firstLink } = formData;


  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log('type:', courseId, ' Link:', firstLink);
    const res = await addFirstLink({ courseId, firstLink });
    console.log('res: ',res)
    if (res.firstLink == firstLink){
      console.log('showpopup true!')
      setShowPopup(true);
    }
  };

  const popupAddLink = () => (
    <div className="popup-container" >
      {console.log('popup!')}
      <div className="btn-close-popup" onClick={setShowPopup(false)}>x</div>
      {console.log('display popup!')}
      <h1>הלינק הראשי נוסף לקורס</h1>
      {alert('ADD!')}
    </div>
  )
    
  

  return <Fragment>
    
      <div className="sign_up-content">
        {showPopup && popupAddLink()}
        <div className="sign_up-right">
          <h2>הוספת לינק ראשי לקורס</h2>
          <form className="form" onSubmit={(e) => onSubmit(e)}>
        
            
            <div className="row-1">
              <input
                type="text"
                placeholder="קוד קורס"
                name="courseId"
                value={courseId}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className="row-1">
              <input
                type="text"
                placeholder="קוד לינק"
                name="firstLink"
                value={firstLink}
                onChange={(e) => onChange(e)}
              />
            </div>
            
            <div className="button">
              <button type="submit" className="btn-submit">
                הוסף
              </button>
            </div>
          </form>
        </div>
        
      </div>
    </Fragment>
  
};



export default AddFirstLink;
