import React, { useState, useEffect } from 'react';

import { getAllPayment } from './../../actions/payment';

const AllPayment = () => {
  const [values, setValues] = useState([]);
  const [error, setError] = useState(false);

  const loadAllPayment = () => {
    console.log('2');
    getAllPayment().then(data => {
      console.log('data', data);
      if (!data) {
        setError(true);
      } else {
        setValues(data);
      }
    });
  };

  useEffect(() => {
    console.log('1');
    loadAllPayment();
  }, []);

  const showError = () => <div>{error.error ? <p>error</p> : null}</div>;

  return (
    <div>
      {values.map((payment, i) => (
        <tr key={i} style={{ padding: '10px' }}>
          <td style={{ padding: '10px' }}>{i}</td>
          <td style={{ padding: '10px' }}>{payment._id}</td>
          <td style={{ padding: '10px' }}>{payment.user.name ? payment.user.name : null }</td>
          <td style={{ padding: '10px' }}>
            {payment.pay.map((p, y) => (
              <tr key={y} style={{ padding: '10px' }}>
                <td style={{ padding: '10px' }}>
                  {p.showcase ? <p>{p.showcase}</p> : <p>{p.course}</p>}
                </td>
                <td style={{ padding: '10px' }}>{p.price}</td>
                <td style={{ padding: '10px' }}>{p.date}</td>
                <td style={{ padding: '10px' }}>
                  {p.expired ? <p> המנוי בתוקף</p> : <p> המנוי הסתיים</p>}
                </td>
                <td style={{ padding: '10px' }}>
                  {p.durationCourseDays ? (
                    <p> ימים {p.durationCourseDays}</p>
                  ) : null}
                </td>
                <td style={{ padding: '10px' }}>
                  {p.durationCourseMonths ? (
                    <p>חודשים {p.durationCourseMonths}</p>
                  ) : null}
                </td>
                <td style={{ padding: '10px' }}>
                  {p.durationCourseYears ? (
                    <p>שנים {p.durationCourseYears}</p>
                  ) : null}
                </td>
              </tr>
            ))}
          </td>
        </tr>
      ))}
    </div>
  );
};

export default AllPayment;
