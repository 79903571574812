import React, { useEffect } from 'react';
import './popup.css';
const $ = require('jquery');

const Popup = ({ closePopup }) => {

  const handleClosePopup = () => {
    closePopup(true);
  };

  return (
    <div className="popup-container">
      <button className="btn-close-popup" onClick={handleClosePopup}>
        x
      </button>
      <iframe id="popup" allowFullScreen className="popup-iframe" src="https://lp.landing-page.mobi/index.php?page=landing&id=413161&token=932fcd5cedfaf2fce8af484c64d66927" />
    </div>
  );
};

export default Popup;
