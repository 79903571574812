import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { login, replaceUser } from '../../actions/auth';
import { getPage, emptyPage } from './../courses/redirectHelper';
import {isMobile,isTablet,mobileModel,browserName,mobileVendor,osVersion,osName,isAndroid} from 'react-device-detect';


const Login = ({ login, isAuthenticated, replaceUser }) => {
  const [fromData, setFormData] = useState({
    email: '',
    password: '',
  });

  const { email, password, ip } = fromData;
  const [showMultiple, setShowMultiple] = useState({
    status:'',
    flag:false,
    msg: ''})

  const [userId, setUserId] = useState('')
  const [userPassword, setUserPassword] = useState('')



  const onChange = (e) =>
    setFormData({ ...fromData, [e.target.name]: e.target.value });


    const closeMultiple = (e) => {
      setShowMultiple({
        flag:false,
        msg:''
      })
    }

    const findBr = (str) => {
      let num = showMultiple.msg.indexOf("br");
      return num;
    }

    const positions = (str, text) => {
      var pos = [], regex = new RegExp("(.*?)" + str, "g"), prev = 0;
      text.replace(regex, function(_, s) {
        var p = s.length + prev;
        pos.push(p);
        prev = p + str.length;
      });
      return pos;
    }
  const printMsg = (text) => {
    let numOfBr = positions('br',showMultiple.msg);
    
    let Msg='';
    for (let i=0;i<numOfBr.length;i++){
      if (i===0){
        Msg = text.substring(0,numOfBr[0]-1) +"\r"
      } else {
        Msg = Msg + text.substring(numOfBr[i-1]+3,numOfBr[i]-1) +"\r"
      }
        
    }
    return <p>{Msg}</p>
  }

  const onReplaceUser = async (e) => {
    e.preventDefault();
    const res = await replaceUser(userId, userPassword)
  }

  const multipleConnection = (device) => (
    <div className="multiple-connection">
      <div className="close-multiple-connection" onClick={e=> closeMultiple(e)}>x</div>
     {positions('br',showMultiple.msg).length > 0 ? <div>
     <p>{showMultiple.msg}</p>
      
     </div>: <p>{showMultiple.msg}</p> }
      {
        showMultiple.status === 402 && 
        
        <form className="btn-row" onSubmit={(e) => onReplaceUser(e)}>
          <button className="btn-primary">השתמש כאן</button>
        
          <div onClick={e=> closeMultiple(e)} className="btn-sec">סגור</div>
        </form>
       
      }
    </div>

  )





  const onSubmit = async (e) => {
    e.preventDefault();
    let device,model,vendor,version,osName1;
    // alert(mobileVendor)
    if (isMobile){
      device='mobile';
      
      model = mobileModel;
      vendor = mobileVendor;
      version = osVersion;
      osName1 = osName;
    } else if  (isTablet){
      version = osVersion;
      osName1 = osName;
      device='tablet';
      
    } else {
      version = osVersion;
      osName1 = osName;
      device='computer';
      
    }
    const browser= browserName;
    const data= await login(email, password,device,model,vendor,version,osName1,browser);
    
    if (typeof data !== "undefined" && data.data.errors.length>0){
      
      if (data.status === 402){
        setUserId(data.data.user.id)
        setUserPassword(data.data.user.password)
        setShowMultiple({flag:true,msg: data.data.errors[0].msg, status:402})
      } else {
        setShowMultiple({flag:true,msg: data.data.errors[0].msg, status:401})
      }
      
    }
  };

  if (isAuthenticated) {
    let page = getPage();
    
    if (page) {
      emptyPage();
      return <Redirect to={page} />;
    }
    return <Redirect to="/dashboard" />;
  }

  return (
    <section className="sign_up">
      <h2>פתרונות במתמטיקה</h2>
      <h3>פשוט, קל ומיידי</h3>
      <div className="sign_up-content">
        <div className="sign_up-right">
          <h2>התחברות</h2>
          <form className="form" onSubmit={(e) => onSubmit(e)}>
            <div className="row-1">
              <input
                type="email"
                placeholder="דואר אלקטרוני"
                name="email"
                value={email}
                onChange={(e) => onChange(e)}
                maxLength="40"
                required
              />
            </div>
            <div className="row-1">
              <input
                type="password"
                placeholder="סיסמה"
                name="password"
                minLength="6"
                maxLength="20"
                value={password}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="button">
              <button type="submit" className="btn-submit">
                התחברות
              </button>
            </div>
            <div className="login">
              <p>
                <Link className="form-btn" to="/recovery">
                  שכחתי את הסיסמה
                </Link>
              </p>
            </div>
            <div className="login">
              <p>
                <Link className="forget-btn" to="/register">
                  עדיין אין לכם חשבון? הירשמו עכשיו!
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
      {
      showMultiple.flag && multipleConnection()
    }
    </section>
    
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  replaceUser: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isAdmin: state.auth.isAdmin,
});

export default connect(mapStateToProps, { login, replaceUser })(Login);
