import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom'

const Alert = ({ alerts }) =>{
  const location = useLocation();

  const checkLocation = () => {
    console.log(location.pathname);
    return location.pathname;
  }

  return (
  alerts !== null &&
  alerts.length > 0 &&
  alerts.map(alert => (
    <div key={alert.id} className="alert-msg">
      {console.log('layout: alert', alert.msg)}
      {checkLocation() !== '/login' && 
      <div className="modal-content">
        <div className="modal-main">
          {console.log('msg:', alert.msg)}

            <p className="modal-text">{alert.msg}</p>
          
         
        </div>
      </div>}
    </div>
  )))
      }

Alert.propTypes = {
  alerts: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  alerts: state.alert
});

export default connect(mapStateToProps)(Alert);
