import React, { useEffect, useState } from 'react';
import { decrypt } from '../../utils/decrypt';
import './courseLinks.css';
import config from '../../utils/configs.json';

const $ = require('jquery');
const EMBEDED_TYPE = {
  VIDEO: 'video',
  PDF: 'pdf',
  DRIVE: 'drive',
  FOLDER: 'folder',
  YOUTUBE: 'youtube',
};

let myInterval = null;
let initialInterval = null;

const LinkItem = ({ linkObject }) => {
  const [loading, setLoading] = useState(true);
  const fileId = decrypt(linkObject.link);

  // useEffect(() => {
  //   console.log('myFrame initialuseEffect');
  //   let l;
  //   if (linkObject.type === EMBEDED_TYPE.PDF) {
  //     l = `https://docs.google.com/gview?url=${fileId}&embedded=true`;
  //   } else if (linkObject.type === EMBEDED_TYPE.VIDEO) {
  //     l = config.vimeoURL + fileId;
  //   } else {
  //     l = fileId;
  //   }

  //   console.log('link: ', l);

  //   initialInterval = setInterval(() => {
  //     console.log('myFrame initialInterval');
  //     $(document).ready(function () {
  //       $('#myFrameItem').attr('src', `${l}`);
  //     });
  //   }, 3000);
  //   console.log('initialInterval 2: ', initialInterval);
  // }, []);

  useEffect(() => {
    setLoading(true);

    $(document).ready(function () {
      $('#myFrameItem').attr(
        'src',
        `${
          linkObject.type === EMBEDED_TYPE.PDF
            ? `https://docs.google.com/gview?url=${fileId}&embedded=true`
            : linkObject.type === EMBEDED_TYPE.VIDEO
            ? config.vimeoURL + fileId
            : linkObject.type === EMBEDED_TYPE.FOLDER
            ? `https://drive.google.com/embeddedfolderview?id=${fileId}#list`
            : linkObject.type === EMBEDED_TYPE.YOUTUBE
            ? `https://www.youtube.com/embed/videoseries?list=${fileId}`:fileId
        }`
      );
    });
    myInterval = setInterval(() => {
      console.log('myFrame myInterval');
      $(document).ready(function () {
        $('#myFrameItem').attr(
          'src',
          `${
            linkObject.type === EMBEDED_TYPE.PDF
              ? `https://docs.google.com/gview?url=${fileId}&embedded=true`
              : linkObject.type === EMBEDED_TYPE.VIDEO
              ? config.vimeoURL + fileId
              : linkObject.type === EMBEDED_TYPE.FOLDER
              ? `https://drive.google.com/embeddedfolderview?id=${fileId}#list`
              : linkObject.type === EMBEDED_TYPE.YOUTUBE
              ? `https://www.youtube.com/embed/videoseries?list=${fileId}`:fileId
          }`
        );
      });
    }, 5000);
  }, [fileId]);

  return (
    <div className="iframe-wrapper">
      <div className="iframe-container">
        <div
          className={
            linkObject.type === 'video' ? 'display-none' : 'iframe-hider'
          }
        >
          <div className="vertically-align">M.O.K</div>
        </div>
        {loading && <div className="iframe-loading">Loading&#8230;</div>}

        <iframe
          onLoad={() => {
            setLoading(false);
            clearInterval(myInterval);
            console.log('myFrame onLoad: ', myInterval);
          }}
          height="600"
          width="1000"
          id="myFrameItem"
          allowFullScreen
          className="iframe-element"
          style={{ backgroundColor: 'white' }}
        />
      </div>
    </div>
  );
};

export default LinkItem;
