import React from 'react';

const Policy = () => {
  return (
    <div style={{ direction: 'rtl' }}>
      <div style={{ textAlign: 'center' }}>
        <h3>תקנון האתר</h3>
      </div>
      <ol style={{ padding: '20px', margin: '20px' }}>
        <li style={{ padding: '10px', margin: '10px' }}>
          <span style={{ fontWeight: 'bold' }}>הרשמה לאתר</span> – לקבלת אישור
          שימוש בשירותים המוצעים באתר יש צורך להירשם וליצור משתמש אישי דרך ממשק
          ההרשמה. כל משתמש M.O.K. אחראי באופן אישי על שמירת פרטיות חשבונו.
        </li>
        <li style={{ padding: '10px', margin: '10px' }}>
          <span style={{ fontWeight: 'bold' }}> שימוש פרטי</span> – בביצוע רכישת
          תכנים באתר M.O.K , מסכים המשתמש כי השימוש בתכנים המוצעים באתר עליהם
          שילם המשתמש, יהיו אך ורק לשימוש אישי בלבד. אי לכך, חל איסור העברת פרטי
          המשתמש לגורם צד שלישי. משתמש העובר על סעיף זה פוגע בחוקי האתר וגורם
          לנזקים כלכליים לבעלי M.O.K. – אי לכך, צפוי להסרת המשתמש לצמיתות
          ולפעולות על פי הדין.
        </li>
        <li style={{ padding: '10px', margin: '10px' }}>
          <span style={{ fontWeight: 'bold' }}> הגנה על תכני האתר</span> – אתר
          M.O.K. מכיל תכני לימוד מקיפים כגון: איורים, מאמרים, גרפיקה, קבצי
          אודיו, קבצי וידאו. כלל תכני הלימודים נמצאים בבעלות האתר M.O.K. ומוגנים
          תחת זכויות היוצרים על פי הדין. אי לכך כל משתמש משלם רשאי להשתמש בתכני
          האתר לצרכיו הלימודיים האישיים בלבד ואך ורק בכפוף לתנאי השימוש הבאים:
          <ul style={{ margin: '10px', padding: '10px' }}>
            <li>
              משלם מתחייב לא לבצע כל פעולה אשר עלולה לפגוע או\ו לגרום להפחתת
              משתמש ערכם של תכני האתר, יוצרי תכני האתר ובעלי האתר.
            </li>
            <li>
              משתמש משלם מתחייב לא לפגוע, לערוך, להקליט, להעתיק, להפיץ, לשדר את
              תכני האתר מתוקפם החוקי תחת זכויות היוצרים של בעלי האתר.
            </li>
            <li>
              משתמש משלם מתחייב לא לאפשר לאחרים לבצע את אחת הפעולות המצוינות
              מעלה.
            </li>
            <li>
              תכני האתר מוגנים תחת זכויות יוצרים על פי הדין ולכן כל שימוש בתכני
              האתר אינטרנט אחר יהווה עבירה על החוק ופגיעה בזכויות היוצרים ותהווה
              הילה להמשך טיפול משפטי.
            </li>
          </ul>
        </li>
        <li style={{ padding: '10px', margin: '10px' }}>
          <span style={{ fontWeight: 'bold' }}> פרטי משתמש</span> – M.O.K. תהיה
          רשאית להשתמש בכל המידע הנתון בידיה אודות המשתמש על מנת לספק את שירותיה
          למשתמש.
          <div>
            M.O.K. רשאית לתת גישה לכל צד שלישי לצורך פיתוח, תחזוקה, שיפור ותפעול
            האתר ושירותיו.
          </div>
          <div>
            M.O.K. רשאית לפנות למשתמש באמצעות הפרטים אשר נמסרו לו בעת ההרשמה
            לאתר ו\או בזמן מגעים עם שירות הלקוחות וכן להציע הצעות אודות המוצר או
            השירות אשר עלולות לעניין את המשתמש. ניתן בכל עת לבקש שלא לקבל הצעות
            אלו בפנייה לכתובת המייל הבאה: mokcompanyltd@gmail.com
          </div>
          <div>
            M.O.K. רשאית לשלוח למשתמש במייל או ב- SMS כל חומר פרסומי או שיווקי
            שלה או של צד שלישי בהתאם לשיקול דעתה.
          </div>
        </li>
        <li style={{ padding: '10px', margin: '10px' }}>
          <span style={{ fontWeight: 'bold' }}>הגבלת גישה לאתר</span> – מנהלי
          האתר רשאים להפסיק או לסרב מתן גישה לאתר או לחלקים ממנו בכל עת ומכל
          סיבה שהיא ללא הודעה מוקדמת. במידה והמשתמש לא קיבל את השירותים המגיעים
          לו מתוקף התשלום שביצע, כספו יוחזר.
        </li>
        <li style={{ padding: '10px', margin: '10px' }}>
          <span style={{ fontWeight: 'bold' }}> הסכם קבלת שירות</span> – על מנת
          להבטיח למשתמש כי יקבל את כל השירות המגיע לו מתוקף התשלום שביצע באתר,
          מתחייבים מנהלי האתר להעניק את מלוא השירותים והתכנים הרשומים תחת
          החבילות הנרכשות ע"י המשתמש. במידה והמשתמש לא קיבל את השירותים המגיעים
          לו, יוחזר כספו.
        </li>
        <li style={{ padding: '10px', margin: '10px' }}>
          <span style={{ fontWeight: 'bold' }}> אבטחת מידע ופרטיות</span> –
          אבטחת המידע ופרטיות מאוד חשובה ולכן מתבצעת הקפדה על תשלום תחת פרוטוקול
          סליקה מאובטח.
        </li>
        <li style={{ padding: '10px', margin: '10px' }}>
          <span style={{ fontWeight: 'bold' }}> קישורים לאתרים אחרים</span> –
          אתר M.O.K. עשוי לכלול קישורים לאתרי אינטרנט של צדדים שלישיים שונים
          לצורך העשרה, העמקה ונוחיות המשתמשים.
          <div>
            אתרים אלו לא אינם בשליטתנו ולכן לא נהיה אחראיים לתוכן הקיים באתרים
            אלו.
          </div>
        </li>
        <li style={{ padding: '10px', margin: '10px' }}>
          <span style={{ fontWeight: 'bold' }}> תנאי שימוש נוספים</span> –
          M.O.K. רשאית לשנות את תנאי השימוש ואין מחויבות לבעלי האתר למסור על כך
          הודעה מראש.
          <div>
            תנאי השימוש החדשים יפורסמו באתר M.O.K. ומועד תחילתם יהיה במועד
            עדכונם באתר, אי לכך מחוייבת M.O.K. לתנאי השימוש המעודכנים תחל ביום
            העדכון.
          </div>
          <div>
            בעל סמכות השיפוט הינו בית המשפט בישראל בהתאם לחוקי מדינת ישראל.
          </div>
        </li>
      </ol>
    </div>
  );
};

export default Policy;
