import React, { useState, useEffect } from 'react';
import { addPaumentShouwcaseToUser } from './../../actions/payment';
import Spinner from '../layout/Spinner';
import Alert from './../layout/Alert';

const AddPaymentShowcase = () => {
  const [data, setData] = useState({
    userId: '',
    showcaseId: '',
    price: '',
    day: '',
    month: '',
    year: ''
  });

  const { userId, showcaseId, price, day, month, year } = data;

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const showError = () => <div>{error ? <p>error</p> : null}</div>;

  const showLoading = () => loading && <Spinner />;

  const onChange = e => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onSubmit = async e => {
    e.preventDefault();
    try {
      setLoading(true);
      const info = await addPaumentShouwcaseToUser(data);
      setLoading(false);
      if (info) {
        Alert('add');
      } else {
        Alert('error');
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="sign_up-content">
      {showLoading()}
      {showError()}

      <div className="sign_up-right">
        <h2>הוספת תשלום showcase</h2>
        <form className="form" onSubmit={e => onSubmit(e)}>
          <div className="row-1">
            <input
              type="text"
              placeholder="id משתמש "
              name="userId"
              value={userId}
              onChange={e => onChange(e)}
            />
          </div>
          <div className="row-1">
            <input
              type="text"
              placeholder="showcase id"
              name="showcaseId"
              value={showcaseId}
              onChange={e => onChange(e)}
            />
          </div>
          <div className="row-1">
            <input
              type="text"
              placeholder="מחיר"
              name="price"
              value={price}
              onChange={e => onChange(e)}
            />
          </div>{' '}
          <div className="row-1">
            <input
              type="text"
              placeholder="למשך כמה ימים"
              name="day"
              value={day}
              onChange={e => onChange(e)}
            />
          </div>
          <div className="row-1">
            <input
              type="text"
              placeholder="למשך כמה חודשים"
              name="month"
              value={month}
              onChange={e => onChange(e)}
            />
          </div>
          <div className="row-1">
            <input
              type="text"
              placeholder="למשך כמה שנים"
              name="year"
              value={year}
              onChange={e => onChange(e)}
            />
          </div>
          <div className="button">
            <button type="submit" className="btn-submit">
              הוסף
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPaymentShowcase;
