import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Ad = () => {
  const [closeAd, setCloseAd] = useState(false);

  const handleAd = () => {
    if (closeAd) {
      setCloseAd(false);
    } else {
      setCloseAd(true);
    }
  };
  return (
    <section className="Ad">
      {!closeAd ? (
        <div className="ad-new-course">
          <div>
            <p
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: '3px',
              }}
            >
              <Link to="/courses/5f5b21f02f203435d78d5502">חדש באתר!</Link>
            </p>
            <p>
              <Link to="/courses/5f5b21f02f203435d78d5502">
                קורס זום מלא לרמת 4 ו- 5 יח"ל!
              </Link>
            </p>
          </div>
          <div className="close-the-add" onClick={(e) => handleAd()}>
            x
          </div>
        </div>
      ) : null}
    </section>
  );
};

export default Ad;
