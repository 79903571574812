export const addPage = (item) => {
  let page = {};
  if (typeof window !== 'undefined') {
    if (localStorage.getItem('page')) {
      page = JSON.parse(localStorage.getItem('page'));
    }
    page = item;

    localStorage.setItem('page', JSON.stringify(page));
  }
};

export const getPage = () => {
  if (typeof window !== 'undefined') {
    if (localStorage.getItem('page')) {
      return JSON.parse(localStorage.getItem('page'));
    }
  }
  return {};
};

export const emptyPage = () => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem('page');
  }
};
